import React, { useContext } from 'react';
import { Context } from '../../store/store';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const Login = () => {
    const handleAuthUserData = useContext(Context).handleAuthUserData;
    const onSuccess = async (res) => {
        const { data } = await axios.get(
            'https://oauth2.googleapis.com/tokeninfo?id_token=' + res.credential
        );
        await handleAuthUserData(data, res.credential);
    };

    const onError = (res) => {
        console.log('Login failed: res:', res);
    };

    return (
        <GoogleLogin
            buttonText="Login"
            onSuccess={onSuccess}
            onError={onError}
            size="large"
            shape="pill"
        />
    );
};

export default Login;
