import React, { useContext } from 'react';
import { Context } from '../../store/store';
import { makeStyles, Avatar, Paper } from '@material-ui/core';
import Logout from './Logout';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'row',
        padding: '3px',
    },
    data: {},
    largeImg: {
        width: theme.spacing(10),
        height: theme.spacing(10),
    },
}));

const Credential = () => {
    const classes = useStyles();
    const user = useContext(Context).user;
    return (
        <Paper className={classes.paper} elevation={3}>
            <div className={classes.data}>
                <span>{user.name}</span>
                <Logout />
            </div>

            <Avatar
                alt="User Profile Img"
                src={user.picture}
                className={classes.largeImg}
            />
        </Paper>
    );
};

export default Credential;
