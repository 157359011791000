import React, { useContext, useState } from 'react';
import { Context } from '../../store/store';
import {
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Fab,
} from '@material-ui/core';
import { CloudDownload, FileCopy, Delete } from '@material-ui/icons';

const columns = [
    {
        id: 'original_name',
        label: 'Name',
        minWidth: 10,
    },
    {
        id: 'updated_at',
        label: 'Generation Date',
        minWidth: 110,
    },
    {
        id: 'size',
        label: 'Size',
        minWidth: 110,
    },
    {
        id: 's3_url',
        label: 'Share | Download | Delete ZIP',
        minWidth: 250,
    },
];

const useStyles = makeStyles({
    root: {
        width: '80%',
    },
    container: {
        maxHeight: 300,
    },
    separateIcons: {
        display: 'flex',
        justifyContent: 'space-between',
    },
});

const FilesTable = () => {
    const handleDeleteArchive = useContext(Context).handleDeleteArchive;
    const handleCurrentDeleted = useContext(Context).handleCurrentDeleted;
    const currentDeleted = useContext(Context).currentDeleted;
    const filesFromDB = useContext(Context).filesFromDB;
    const toastrNotify = useContext(Context).toastrNotify;
    const classes = useStyles();
    const [open, setOpen] = useState(false);

    const handleDownload = (url) => {
        window.location.assign(url);
    };
    const handleCopyToClipboard = (text) => {
        setOpen(true);
        toastrNotify('Copied to the clipboard', 'info');
        navigator.clipboard.writeText(text);
    };
    const handleDelete = async (id, index) => {
        await handleCurrentDeleted(index);
        await handleDeleteArchive(id);
    };

    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const niceBytes = (x) => {
        let l = 0,
            n = parseInt(x, 10) || 0;

        while (n >= 1000 && ++l) {
            n = n / 1000;
        }

        return n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l];
    };

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table
                    className={classes.container}
                    stickyHeader
                    aria-label="sticky table"
                >
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell
                                    key={column.id}
                                    style={{
                                        minWidth: column.minWidth,
                                    }}
                                >
                                    {column.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filesFromDB.map((row, indexRow) => {
                            return (
                                <TableRow
                                    hover
                                    role="checkbox"
                                    tabIndex={-1}
                                    key={indexRow}
                                >
                                    {columns.map((column, index) => {
                                        //const value = row[column.id];
                                        let value;
                                        if (column.id === 's3_url') {
                                            value = (
                                                <div
                                                    className={
                                                        classes.separateIcons
                                                    }
                                                >
                                                    <Fab
                                                        color="primary"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            handleCopyToClipboard(
                                                                row[column.id]
                                                            )
                                                        }
                                                    >
                                                        <FileCopy />
                                                    </Fab>
                                                    <Fab
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            handleDownload(
                                                                row[column.id]
                                                            )
                                                        }
                                                    >
                                                        <CloudDownload />
                                                    </Fab>
                                                    <Fab
                                                        color="secondary"
                                                        aria-label="edit"
                                                        onClick={() =>
                                                            handleDelete(
                                                                row['id'],
                                                                indexRow
                                                            )
                                                        }
                                                        disabled={
                                                            currentDeleted &&
                                                            currentDeleted ===
                                                                indexRow
                                                        }
                                                    >
                                                        <Delete />
                                                    </Fab>
                                                </div>
                                            );
                                        } else if (column.id === 'size') {
                                            value = niceBytes(row[column.id]);
                                        } else {
                                            value = row[column.id];
                                        }
                                        return (
                                            <TableCell key={index}>
                                                {value}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default FilesTable;

// import React, { useContext } from 'react';
// import { Context } from '../../store/store';
// import { makeStyles, Container } from '@material-ui/core';

// const useStyles = makeStyles((theme) => ({
//     dashboardContainer: {},
// }));

// const UploadCard = () => {
//     // const files = useContext(Context).files;
//     const classes = useStyles();
//     return (
//         <Container maxWidth="sm" className={classes.dashboardContainer}>
//             adasd
//         </Container>
//     );
// };

// export default UploadCard;
