import React, { useContext } from 'react';
import { Context } from './store/store';
import logo from './logo.svg';
import './App.css';
import Login from './components/auth/Login';
import Credential from './components/auth/Credential';
import Dashboard from './pages/Dashboard';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core';
import Dropzone from 'react-dropzone';

const useStyles = makeStyles((theme) => ({
    dropzone: {
        border: `2px solid ${theme.palette.primary.main}`,
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: theme.spacing(10),
        outline: 'none',
        margin: '5px',
    },
    containerDropZoneAlignCredential: {
        width: '70%',
    },
    dropzoneAlignCredential: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const App = () => {
    const user = useContext(Context).user;
    const classes = useStyles();
    const isLoggedIn = useContext(Context).isLoggedIn;
    const handleUploadedFiles = useContext(Context).handleUploadedFiles;
    const onDrop = async (acceptedFiles, rejFiles) => {
        const filterAcceptedFiles = await acceptedFiles.filter(
            (f) => f.type !== ''
        );
        handleUploadedFiles(filterAcceptedFiles);
    };

    let initPage = (
        <>
            <Login />
        </>
    );

    if (Object.keys(user).length && isLoggedIn) {
        initPage = (
            <>
                <div className={classes.dropzoneAlignCredential}>
                    <Dropzone onDrop={onDrop}>
                        {({ getRootProps, getInputProps }) => (
                            <div
                                {...getRootProps({
                                    className: classes.dropzone,
                                })}
                            >
                                <input {...getInputProps()} />
                                <p>
                                    Drag 'n' files here, or click to select
                                    files
                                </p>
                            </div>
                        )}
                    </Dropzone>
                    <Credential />
                </div>
                <Dashboard />
            </>
        );
    }

    return (
        <div className="App">
            <ToastContainer />
            <header className="App-header" style={{ minHeight: 5 + 'vh' }}>
                <img src={logo} className="App-logo" alt="logo" />
                <span className="App-link"> DATA COMPRESSION CLICK</span>
            </header>
            <div
                className="App-header"
                style={{
                    minHeight: 12.2 + 'vh',
                }}
            >
                <div className={classes.containerDropZoneAlignCredential}>
                    {initPage}
                </div>
            </div>
        </div>
    );
};

export default App;
