import React, { useContext } from 'react';
import { Context } from '../../store/store';
import {
    makeStyles,
    LinearProgress,
    Paper,
    List,
    Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    uploadCard: {
        backgroundColor: theme.palette.background.paper,
    },
    list: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
        position: 'relative',
        overflow: 'auto',
        borderRadius: 5,
    },
}));

const UploadCard = () => {
    const files = useContext(Context).files;
    const classes = useStyles();
    let filesContext = (
        <>
            <Paper elevation={5}>
                <h5>Waiting for a file...</h5>
            </Paper>
        </>
    );
    if (files.length > 0) {
        filesContext = files.map((file, index) => (
            <Paper elevation={5} key={index}>
                <h5>{file._path ? file._path : file.path}</h5>
                <div
                    style={{
                        paddingLeft: 50,
                        paddingRight: 50,
                        padding: 10,
                        paddingBottom: 10,
                    }}
                >
                    <Typography>
                        {file.status} - {file.percentage} %
                    </Typography>
                    <LinearProgress
                        variant="determinate"
                        value={file.percentage}
                    />
                </div>
            </Paper>
        ));
    }
    return (
        <>
            <List className={classes.list}>
                {filesContext}
                {/* <Paper elevation={5}>
                    <h5>Nombre de mi archivo a cargar</h5>
                    <div
                        style={{
                            paddingLeft: 50,
                            paddingRight: 50,
                            padding: 10,
                            paddingBottom: 10,
                        }}
                    >
                        <Typography>en progreso - 20 %</Typography>
                        <LinearProgress variant="determinate" value={20} />
                    </div>
                </Paper>
                <Paper elevation={5}>
                    <h5>Nombre de mi archivo a cargar</h5>
                    <div
                        style={{
                            paddingLeft: 50,
                            paddingRight: 50,
                            padding: 10,
                            paddingBottom: 10,
                        }}
                    >
                        <Typography>en progreso - 20 %</Typography>
                        <LinearProgress variant="determinate" value={20} />
                    </div>
                </Paper>
                <Paper elevation={5}>
                    <h5>Nombre de mi archivo a cargar</h5>
                    <div
                        style={{
                            paddingLeft: 50,
                            paddingRight: 50,
                            padding: 10,
                            paddingBottom: 10,
                        }}
                    >
                        <Typography>en progreso - 20 %</Typography>
                        <LinearProgress variant="determinate" value={20} />
                    </div>
                </Paper> */}
            </List>
        </>
    );
};

export default UploadCard;
