import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Context } from '../../store/store';
import Button from '@material-ui/core/Button';
import ExitToApp from '@material-ui/icons/ExitToApp';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(1),
    },
}));

function Logout() {
    const classes = useStyles();
    const handleLogout = useContext(Context).handleLogout;

    return (
        <div>
            <Button
                variant="contained"
                color="primary"
                className={classes.button}
                endIcon={<ExitToApp></ExitToApp>}
                onClick={() => handleLogout()}
            >
                Logout
            </Button>
        </div>
    );
}

export default Logout;
