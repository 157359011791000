import React, { useContext } from 'react';
import { Context } from '../store/store';
import FilesTable from '../components/data-compress/FilesTable';
import UploadCard from '../components/data-compress/UploadCard';
import { makeStyles, Container } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    dashboardContainer: {
        display: 'flex',
        maxHeight: 200,
        justifyContent: 'center',
    },
}));

const Dashboard = () => {
    // const files = useContext(Context).files;
    const classes = useStyles();
    return (
        <Container className={classes.dashboardContainer}>
            <UploadCard />
            <FilesTable />
        </Container>
    );
};

export default Dashboard;
