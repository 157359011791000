import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';

export const Context = React.createContext({
    user: {},
    token: '',
    handleLogout: () => {},
    toastrNotify: (message, type) => {},
    files: [],
    filesFromDB: [],
    isLoggedIn: false,
});

export const Provider = (props) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState('');
    const [files, setFiles] = useState([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [filesFromDB, setFilesFromDB] = useState([]);
    const [currentDeleted, setCurrentDeleted] = useState(null);

    const [handleUploaded, setHandleUploaded] = useState(false);

    useEffect(() => {
        try {
            if ((files.length > 0, handleUploaded)) {
                console.log('USE EFECT => ', files);
                handleGeneratePreSignedUrl();
                setHandleUploaded(false);
            }
        } catch (error) {
            console.error('Error => ', error);
        }
    }, [files]);

    const handleCurrentDeleted = async (buttonIndex = null) => {
        try {
            buttonIndex
                ? setCurrentDeleted(buttonIndex)
                : setCurrentDeleted(null);
        } catch (error) {
            console.error('Error handleCurrentDeleted() => ', error);
        }
    };

    const handleUploadedFiles = async (f) => {
        try {
            setHandleUploaded(true);

            f.forEach((element) => {
                element.status = 'Handle file';
                element.percentage = 10;
                element.sendToS3 = false;
                let nameSplit = element.name.split('.');
                let extension = '.' + nameSplit.pop();
                let withoutExtension = nameSplit.join('/');
                let newNameAndPath = withoutExtension + Date.now() + extension;
                element._name = newNameAndPath;
                element._path = newNameAndPath;

                setFiles((prevState) => [...prevState, element]);
            });
        } catch (error) {
            console.error('Error handleUploadedFiles() => ', error);
        }
    };

    const handleGeneratePreSignedUrl = async () => {
        try {
            const types = [];
            const sizes = [];
            const paths = [];
            const filesWithOutProcess = files.filter((f) => {
                return f.sendToS3 === false;
            });
            filesWithOutProcess.forEach((f) => {
                types.push(f.type);
                sizes.push(f.size);
                paths.push(f._path);
            });

            const { data } = await axios.post(
                process.env.REACT_APP_API_URL + 'pre-signed-url',
                {
                    user,
                    files: filesWithOutProcess,
                    types,
                    sizes,
                    paths,
                }
            );
            for (const url in data.urls) {
                files.forEach((file, index) => {
                    if (file._name == url) {
                        setFiles((currentFiles) => {
                            const updatedFiles = [...currentFiles];
                            updatedFiles[index].status =
                                'Generated S3 Pre Signed URL';
                            updatedFiles[index].percentage = 40;
                            return updatedFiles;
                        });
                    }
                });
            }
            await handleUploadFilesToS3(data, sizes);
        } catch (error) {
            console.error('Error handleGeneratePreSignedUrl() => ', error);
        }
    };

    const handleUploadFilesToS3 = async (data, sizes) => {
        try {
            for (const url in data.urls) {
                let payload = files.find((file) => {
                    let customFileName = data.user_id + '/' + file._name;
                    if (customFileName == url && !file.sendToS3) {
                        return file;
                    }
                });
                if (Object.keys(payload).length === 0) {
                    console.warn('handleUploadFilesToS3 payload empty');
                    return;
                }
                let index = files.indexOf(payload);
                setFiles((currentFiles) => {
                    const updatedFiles = [...currentFiles];
                    updatedFiles[index].sendToS3 = true;
                    updatedFiles[index].status = 'Wait...Sending file...';
                    updatedFiles[index].percentage = 60;
                    return updatedFiles;
                });
                let response = await fetch(data.urls[url], {
                    method: 'PUT',
                    body: payload,
                    headers: {
                        'Content-Type': payload.type,
                    },
                });
                if (response.ok) {
                    setFiles((currentFiles) => {
                        const updatedFiles = [...currentFiles];
                        updatedFiles[index].status = 'Compressing the file...';
                        updatedFiles[index].percentage = 80;
                        return updatedFiles;
                    });
                    // CALCULATED ALL TIME OF WAITING FOR COMPRESS PROCESS
                    const time = 14000;
                    setTimeout(() => {
                        handleGetFilesByEmail(user, token, {
                            fromCompressProcess: true,
                        });
                    }, time);
                    // CALCULATED ALL TIME OF WAITING FOR COMPRESS PROCESS
                } else {
                    console.warn('response warning => ', response);
                    setFiles((currentFiles) => {
                        const updatedFiles = [...currentFiles];
                        delete updatedFiles[index];
                        return updatedFiles;
                    });
                }
            }
        } catch (error) {
            console.error('Error handleUploadFilesToS3() => ', error);
        }
    };

    const handleGetFilesByEmail = async (
        user,
        token,
        fromCompressProcess = false
    ) => {
        try {
            const { data } = await axios.post(
                process.env.REACT_APP_API_URL + 'get-compressed-files',
                {
                    user,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            if (fromCompressProcess) {
                files.forEach((file, index) => {
                    data.forEach((dt) => {
                        if (file._path === dt.name_extension) {
                            setFiles((currentFiles) => {
                                const updatedFiles = [...currentFiles];
                                updatedFiles[index].status =
                                    'Succesfull Compression';
                                updatedFiles[index].percentage = 100;
                                return updatedFiles;
                            });
                        }
                    });
                });
            }
            setFilesFromDB(data);
        } catch (error) {
            console.error('Error handleGetFilesByEmail() => ', error);
        }
    };

    const handleDeleteArchive = async (id) => {
        try {
            toastrNotify(`Deleting file!`, 'info');
            console.log('token => ', token);
            const { data } = await axios.post(
                process.env.REACT_APP_API_URL + 'delete-final-archive',
                {
                    id,
                },
                {
                    headers: {
                        Authorization: token,
                    },
                }
            );
            if (data.ok) {
                const updatedFiles = filesFromDB.filter(
                    (obj) => obj.id !== data.id
                );
                setFilesFromDB(updatedFiles);
                toastrNotify(`Deleted Archive Succesfully!`, 'success');
                handleCurrentDeleted();
            }
        } catch (error) {
            console.error('Error handleDeleteArchive() => ', error);
        }
    };

    const handleAuthUserData = async (user, token) => {
        try {
            // console.log('user => ', user);
            setUser(user);
            setToken(token);
            setIsLoggedIn(true);
            toastrNotify(`Welcome ${user.email}!`, 'success');
            await handleGetFilesByEmail(user, token);
        } catch (error) {
            console.error('Error handlerAuthUserData() => ', error);
        }
    };

    const handleLogout = () => {
        try {
            setIsLoggedIn(false);
            setUser({});
            toastrNotify('Bye bye! See ya!', 'success');
        } catch (error) {
            console.error('handleLogout() => ', error);
        }
    };

    const toastrNotify = (message, type) => {
        try {
            toast(message, { type: type });
        } catch (error) {
            console.error('Error toastrNotify () => ', error);
        }
    };

    return (
        <Context.Provider
            value={{
                handleAuthUserData,
                user,
                handleLogout,
                files,
                handleUploadedFiles,
                filesFromDB,
                handleGetFilesByEmail,
                toastrNotify,
                handleDeleteArchive,
                handleCurrentDeleted,
                currentDeleted,
                isLoggedIn,
            }}
        >
            {props.children}
        </Context.Provider>
    );
};
